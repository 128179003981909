import React, { useState } from "react";
import { Button, Table } from "flowbite-react";
import { updateCompany } from "../../api/companiesConnector";
import LoadingComponent from "../../../../components/LoadingComponent";
import { useAppContext } from "../../../../components/AppContext/AppContext";
import Company from "../../domain/Company";

interface UpdateCompanyProps {
    selectedCompany: Company;
    onUpdateSuccess: () => void;
}

const UpdateCompany: React.FC<UpdateCompanyProps> = ({ selectedCompany, onUpdateSuccess }) => {
    const { throwError, showSuccess } = useAppContext();
    const [formData, setFormData] = useState<Company>(selectedCompany);
    const [isLoading, setIsLoading] = useState(false);

    const handleUpdate = async () => {
        setIsLoading(true);
        try {
            await updateCompany(formData.id, formData);
            showSuccess("Entreprise mise à jour avec succès !");
            onUpdateSuccess();
        } catch (error) {
            switch (error) {
                case 401:
                    throwError("Erreur 401 : Non autorisé. Veuillez rafraîchir la page ou vous reconnectez à MyHexagone.");
                    break;
                case 404:
                    throwError("Erreur 404 : Ressource non trouvée.");
                    break;
                case 500:
                    throwError("Erreur 500 : Problème serveur. Réessayez plus tard.");
                    break;
                default:
                    throwError(`Erreur ${error} : Une erreur inattendue est survenue.`);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    return (
        <div className="mx-4 my-11 px-4">
        <div className="w-full mx-4 my-11 px-4">
            <h2 className="text-xl font-semibold mb-4">Mettre à jour une entreprise</h2>
            <LoadingComponent isLoading={isLoading}>
                <Table className="mt-4">
                    <thead >
                    <tr>
                        <th>Numéro de contrat</th>
                        <th className="px-2">Siret</th>
                        <th>Actions</th>
                        <th>Dénomination</th>
                        <th className="px-2">Siret</th>
                    </tr>
                    </thead>
                    <tbody className="p-4">
                    <tr>
                        <td>
                            <input
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                            />
                        </td>
                        <td>
                            <input
                                type="text"
                                name="siret"
                                value={formData.siret}
                                onChange={handleChange}
                            />
                        </td>
                        <td>
                            <Button className="w-32" color="success" onClick={handleUpdate}>
                                Mettre à jour
                            </Button>
                        </td>
                    </tr>
                    </tbody>
                </Table>
            </LoadingComponent>
        </div>
        </div>
    );
};

export default UpdateCompany;

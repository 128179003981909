import {useEffect, useState} from "react";
import Company from "../../domain/Company";
import { getAllCompanies, getCompanyById } from "../../api/companiesConnector";
import UpdateCompany from "./UpdateCompany";
import DeleteCompany from "./DeleteCompany";

export default function CompaniesList() {
    const [companiesList, setCompaniesList] = useState<Company[]>([]);
    const [selectedCompanyId, setSelectedCompanyId] = useState<string | null>(null);
    const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);

    useEffect(() => {
        (async () => {
            try {
                const response = await getAllCompanies();
                setCompaniesList(response.data);
            } catch (error) {
                console.error("Erreur lors de la récupération des entreprises", error);
            }
        })();
    }, []);

    const handleCompanySelect = async (companyId: string) => {
        setSelectedCompanyId(companyId);
        try {
            const response = await getCompanyById(companyId);
            setSelectedCompany(response.data);
        } catch (error) {
            console.error("Erreur lors de la récupération de l'entreprise", error);
        }
    };

    const handleActionSuccess = () => {
        setSelectedCompanyId(null);
        setSelectedCompany(null);
    };

    return (
        <div className="mx-4 p-4 bg-white rounded shadow-md border-gray-200 dark:border-gray-700">
            <h2 className="text-xl font-semibold mb-4">Cliquez sur une entreprise:</h2>

            {companiesList.length > 0 ? (
              <ul className="ml-6">
              {companiesList.map((company) => (
                  <li
                      key={company.id}
                      className="my-2 uppercase cursor-pointer border-b-2"
                      onClick={() => handleCompanySelect(company.id)}
                  >
                      <p>Dénomination: {company.name}</p>
                      <p>Siret: {company.siret}</p>
                  </li>
              ))}
          </ul>
    )
:
    (
        <p>Chargement des entreprises...</p>
    )
}

{
    selectedCompany && (
        <div className="mt-4">
            <UpdateCompany
                selectedCompany={selectedCompany}
                onUpdateSuccess={handleActionSuccess}
                    />
                    <DeleteCompany
                        selectedCompanyId={selectedCompanyId}
                        onDeleteSuccess={handleActionSuccess}
                        selectedCompany={selectedCompany}
                    />
                </div>
            )}
        </div>
    );
}

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {I18nextProvider} from "react-i18next";
import AppRouter from "./components/AppRouter/AppRouter";
import "./global.css";
import {AuthenticationContextContainer} from "./components/authentication/AuthenticationContext";
import AuthenticatedPage from "./components/authentication/AuthenticatedPage";
import {AppContextContainer} from "./components/AppContext/AppContext";
import {CustomFlowbiteTheme, Flowbite} from "flowbite-react";
import i18n from "./i18n/i18n";
import {BrowserRouter} from "react-router-dom";

// Extends Window props so that we can add environments variables from /public/env-config.js, which are extracted from .env file.
declare global {
    interface Window {
        _env_: WindowData;
    }
}

export const defaultNS = "common";

interface WindowData {
    APPRENTICESHIP_API_URL: string;
    USERS_API_URL: string;
    ENROLMENT_API_URL: string;
    ASSESSMENTS_API_URL: string;
    AUTH_CLIENT_ID: string;
    AUTH_URL: string;
    AUTH_REALM: string;
    QUIZ_URL: string;
    ADMIN_QUIZ_URL: string;
}

const customTheme: CustomFlowbiteTheme = {
    button: {
        color: {
            primary: 'bg-white text-hexagone-purple hover:bg-hexagone-darker-light-grey focus:ring-hexagone-purple',
            secondary: 'bg-hexagone-purple text-hexagone-light-grey hover:bg-hexagone-darker-purple focus:ring-hexagone-purple',
            success: 'bg-green-700 text-hexagone-light-grey hover:bg-green-800 focus:ring-green-900',
            failure: 'bg-red-700 text-hexagone-light-grey hover:bg-red-800 focus:ring-red-900',
            info: 'bg-blue-700 text-hexagone-light-grey hover:bg-blue-800 focus:ring-blue-900',
            light: 'bg-white text-hexagone-text hover:bg-hexagone-darker-light-grey focus:ring-hexagone-purple',
            primaryBordered: "bg-white text-hexagone-purple hover:bg-hexagone-darker-light-grey focus:ring-hexagone-purple border border-hexagone-purple"
        },
    },
    tabs: {
        tablist: {
            styles: {
                fullWidth: "w-full text-sm font-medium divide-x divide-gray-200 shadow grid grid-flow-col dark:divide-gray-700 dark:text-gray-400 rounded-lg"
            },
            tabitem: {
                base: "flex items-center justify-center p-4 text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500 focus:outline-none",
                styles: {
                    fullWidth: {
                        base: "bg-white first:rounded-s-lg last:rounded-e-lg ml-0 first:ml-0 w-full flex focus:ring-0",
                        active: {
                            on: "p-4 text-hexagone-purple bg-gray-100 active",
                            off: "hover:text-gray-700 hover:bg-gray-50 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
                        }
                    },
                    default: {
                        base: "rounded-t-lg focus:ring-2 focus:ring-hexagone-purple",
                        active: {
                            on: "bg-gray-100 text-hexagone-purple",
                            off: "text-gray-500 hover:bg-gray-50 hover:text-gray-600 dark:text-gray-400 dark:hover:bg-gray-800  dark:hover:text-gray-300"
                        }
                    }
                }
            }
        }
    },
    pagination: {
        base: "mx-auto md:mx-0",
        pages: {
            base: "inline-flex items-center -space-x-px justify-center flex-wrap"
        }
    },
    spinner: {
        color: {
            info: "fill-hexagone-purple"
        }
    },
    card: {
        root: {
            children: "flex h-full flex-col justify-center p-6"
        }
    },
    progress: {
        bar: "space-x-2 rounded-full text-center font-medium leading-none text-white dark:text-cyan-100"
    },
    tooltip: {
        target: ""
    }
};

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <BrowserRouter>
    <AuthenticationContextContainer.Provider>
        <I18nextProvider i18n={i18n}>
            <AuthenticatedPage>
                <AppContextContainer.Provider>
                    <Flowbite theme={{theme: customTheme}}>
                        <AppRouter/>
                    </Flowbite>
                </AppContextContainer.Provider>
            </AuthenticatedPage>
        </I18nextProvider>
    </AuthenticationContextContainer.Provider>
</ BrowserRouter >
);
import {Route, Routes} from "react-router-dom";
import UsersManagement from "../../modules/users";
import Dashboard from "../../modules/dashboard";
import LoadingComponent from "../LoadingComponent";
import {useAppContext} from "../AppContext/AppContext";
import Layout from "../Layout/Layout";
import EnrolmentTestsQuizzes from "../../modules/enrolment-tests/Quizzes";
import EnrolmentTestsQuestionsBank from "../../modules/enrolment-tests/QuestionsBank";
import AssessmentsTestsQuestionsBank from "../../modules/assessments/QuestionsBank";
import Assessments from "../../modules/assessments/Assessments";
import MyAssessments from "../../modules/assessments/MyAssessments";
import Subjects from "../../modules/assessments/Subjects";
import WiFi from "../../modules/tools/Wi-Fi";
import Olvid from "../../modules/tools/Olvid";
import Apprenticeship from "../../modules/apprenticeship/pages/Apprenticeship";
import ExternalRedirection from "../ExternalRedirection/ExternalRedirection";

export default function AppRouter() {
    const {isAppContextLoading} = useAppContext();

    return (
        <LoadingComponent isLoading={isAppContextLoading}>
                <Routes>
                    <Route path={"/"} element={<Layout />}>
                        <Route index element={<Dashboard />} />
                        <Route path={"/*"} element={<Dashboard />} />
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/users/*" element={<UsersManagement />} />
                        <Route path="/users/:userId" element={<UsersManagement />} />
                        <Route path="/tools/wifi" element={<WiFi />} />
                        <Route path="/tools/olvid" element={<Olvid />} />
                        <Route path="/enrolment-tests/quizzes" element={<EnrolmentTestsQuizzes />} />
                        <Route path="/enrolment-tests/quizzes/:quizId" element={<EnrolmentTestsQuizzes />} />
                        <Route path="/enrolment-tests/*" element={<EnrolmentTestsQuestionsBank />} />
                        <Route path="/enrolment-tests/questions-bank/:subjectId" element={<EnrolmentTestsQuestionsBank />} />
                        <Route path="/assessments/subjects" element={<Subjects />} />
                        <Route path="/assessments/subjects/:subjectId" element={<Subjects />} />
                        <Route path="/assessments/questions-bank" element={<AssessmentsTestsQuestionsBank />} />
                        <Route path="/assessments/questions-bank/:subjectId" element={<AssessmentsTestsQuestionsBank />} />
                        <Route path="/assessments/*" element={<Assessments />} />
                        <Route path="/assessments/:assessmentId" element={<Assessments />} />
                        <Route path="/my/assessments" element={<MyAssessments />} />
                        <Route path="/apprenticeship" element={<Apprenticeship />}/>
                        <Route
                            path="/external-link"
                            element={<ExternalRedirection to="https://eureka.ecole-hexagone.com/portal/academic" />}
                        />
                    </Route>
                </Routes>
        </LoadingComponent>
    );
}
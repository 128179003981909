import React, { useState } from "react";
import CreateCompany from "./CreateCompany";
import "../../../../index.css";
import {FindCompany} from "./FindCompany";
import CompaniesList from "./CompaniesList";
import CompaniesLength from "./CompaniesLength";

export default function AdminCompany() {
    const [isCreateCompanyOpen, setCreateCompanyOpen] = useState(false);
    const [isFindCompanyOpen, setFindCompanyOpen] = useState(false);
    const [isCompaniesListOpen, setIsCompaniesListOpen] = useState(false);

    const toggleCreateCompany = () => {
        setCreateCompanyOpen(prevState => !prevState);
    };
    const toggleFindCompany = () => {
        setFindCompanyOpen(prevState => !prevState);
    };

    const toggleCompaniesList = () => {
        setIsCompaniesListOpen(prevState => !prevState)
    }

    return (
        <div className="my-11 flex flex-col items-center">
            <CompaniesLength />
            <button
                onClick={toggleFindCompany}
                className={isFindCompanyOpen ? "w-[95%] p-4 apprenticeship-btn-open bg-[#310046] text-white border-2 border-[#310046] z-10 font-bold" : "w-[95%] p-4 mx-4 apprenticeship-btn-close bg-[#310046] text-white font-bold z-10"}>
                {isFindCompanyOpen ? "Annuler" : "Chercher une entreprise"}
            </button>
            <div className={isFindCompanyOpen ? "w-[95%] p-4 apprenticeship-card z-0" : "mb-11 z-0"}>
                {isFindCompanyOpen && <FindCompany/>}
            </div>
            <button
                className={isCompaniesListOpen ? "w-[95%] p-4 mx-4 apprenticeship-btn-open bg-[#310046] text-white border-2 border-[#310046] z-10 font-bold" : "w-[95%] p-4 mx-4 apprenticeship-btn-close bg-[#310046] text-white font-bold"}
                onClick={toggleCompaniesList}>
                Liste complète des entreprises
            </button>
            <div className={isCompaniesListOpen ? "w-[95%] p-4 mx-4 apprenticeship-card z-0" : "mb-11 z-0"}>
                {isCompaniesListOpen && <CompaniesList />}
            </div>
            <button
                onClick={toggleCreateCompany}
                className={isCreateCompanyOpen ? "w-[95%] p-4 mx-4 apprenticeship-btn-open bg-[#310046] text-white border-2 border-[#310046] z-10 font-bold" : "w-[95%] p-4 mx-4 apprenticeship-btn-close bg-[#310046] text-white font-bold"}>
                {isCreateCompanyOpen ? "Annuler" : "Créer une fiche entreprise"}
            </button>
            <div className={isCreateCompanyOpen ? "w-[95%] p-4 mx-4 apprenticeship-card z-0" : "mb-11 z-0"}>
                {isCreateCompanyOpen && <CreateCompany/>}
            </div>

        </div>
    );
}

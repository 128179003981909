import axios, { AxiosResponse } from "axios";
import Company from "../domain/Company";

const API_URL = window._env_.APPRENTICESHIP_API_URL + "companies";

const config = {
    withCredentials: true,
    headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
    },
};

export function getCompanies(searchCriteria?: string): Promise<AxiosResponse<Company[]>> {
    const url = `${window._env_.APPRENTICESHIP_API_URL}companies`;
    const config = {
        params: { search: searchCriteria || "" },
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.get(url, config);
}
export function getAllCompanies(): Promise<AxiosResponse<Company[]>> {
    const url = `${API_URL}`;
    return axios.get(url, config);
}

export function getCompanyById(companyId: string): Promise<AxiosResponse<Company>> {
    const url = `${API_URL}/${companyId}`;
    return axios.get(url, config);
}

export function createCompany(body: any): Promise<AxiosResponse<Company>> {
    const url = `${API_URL}`;
    return axios.post(url, body, config);
}

export function updateCompany(companyId: string, body: any): Promise<AxiosResponse<Company>> {
    const url = `${API_URL}/${companyId}`;
    return axios.patch(url, body, config);
}

export function deleteCompany(companyId: string | null): Promise<AxiosResponse<void>> {
    const url = `${API_URL}/${companyId}`;
    return axios.delete(url, config);
}



import React, { useCallback, useEffect, useState } from "react";
import Contract from "../../domain/Contract";
import { Card } from "flowbite-react";
import { useAppContext } from "../../../../components/AppContext/AppContext";
import SearchBar from "../../../../components/SearchBar";
import LoadingComponent from "../../../../components/LoadingComponent";
import { getContracts } from "../../api/contractsConnector";
import { getStudents } from "../../api/studentsConnector";
import {getCompanies} from "../../api/companiesConnector";

interface ContractTableProps {
    contracts: Contract[];
    onSelectContract: (contractId: string) => void;
}

const ContractTable: React.FC<ContractTableProps> = ({ contracts, onSelectContract }) => {
    return (
        <table className="min-w-full divide-y divide-gray-200">
            <thead>
            <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Student</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Company</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Poste
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Start
                    Date
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">End
                    Date
                </th>
            </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
            {contracts.map(contract => (
                <tr
                    key={contract.id}
                    onClick={() => onSelectContract(contract.id)}
                    className="cursor-pointer hover:bg-gray-100"
                >
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{contract.id}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {contract.student?.firstName} {contract.student?.lastName}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {contract.company?.name || "N/A"}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {contract.studentFunction || "N/A"}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {new Date(contract.startDate).toLocaleDateString()}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {new Date(contract.endDate).toLocaleDateString()}
                    </td>
                </tr>
            ))}
            </tbody>
        </table>
    );
};

export default function ContractSearchBar({onContractSelect}: { onContractSelect: (contractId: string) => void }) {
    const [searchCriteria, setSearchCriteria] = useState("");
    const [filteredContracts, setFilteredContracts] = useState<Contract[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const { throwError } = useAppContext();
    const [noResults, setNoResults] = useState(false);

    const fetchContracts = useCallback(() => {
        setIsLoading(true);
        Promise.all([getContracts(), getStudents(), getCompanies()])
            .then(([contractResponse, studentResponse, companyResponse]) => {
                const allContracts = contractResponse.data;

                const filtered = allContracts.filter(contract => {
                    return (
                        contract.id.toString().includes(searchCriteria) ||
                        (contract.company?.name?.toLowerCase() || "").includes(searchCriteria.toLowerCase()) ||
                        (contract.student?.lastName?.toLowerCase() || "").includes(searchCriteria.toLowerCase()) ||
                        (contract.student?.firstName?.toLowerCase() || "").includes(searchCriteria.toLowerCase()) ||
                        (contract.studentFunction?.toLowerCase() || "").includes(searchCriteria.toLowerCase())
                    );
                });

                setFilteredContracts(filtered);
                setNoResults(filtered.length === 0);
                setIsLoading(false);
            })
            .catch((error) => {
                if (error.response) {
                    switch (error.response.status) {
                        case 401:
                            throwError("Erreur 401 : Non autorisé. Veuillez rafraîchir la page ou vous reconnectez à MyHexagone.");
                            break;
                        case 404:
                            throwError("Erreur 404 : Ressource non trouvée.");
                            break;
                        case 500:
                            throwError("Erreur 500 : Problème serveur. Réessayez plus tard.");
                            break;
                        default:
                            throwError(`Erreur ${error.response.status} : Une erreur inattendue est survenue.`);
                    }
                }
                setIsLoading(false);
            });
    }, [searchCriteria, throwError]);


    useEffect(() => {
        if (searchCriteria) {
            fetchContracts();
        } else {
            setFilteredContracts([]);
            setNoResults(false);
        }
    }, [searchCriteria, fetchContracts]);

    return (
        <Card className="m-4">
            <div className="inline-flex">
                <SearchBar submit={setSearchCriteria} placeholder="Rechercher un contrat..." />
            </div>
            <div className="my-2 relative overflow-auto">
                <LoadingComponent isLoading={isLoading}>
                    <ContractTable contracts={filteredContracts} onSelectContract={onContractSelect} />
                    {noResults && (
                        <div className="text-center text-sm text-gray-500 mt-2">
                            Aucun contrat ne correspond à votre recherche
                        </div>
                    )}
                </LoadingComponent>
            </div>
        </Card>
    );
}

import {useEffect} from "react";

interface ExternalRedirectionProps {
    to: string;
}

const ExternalRedirection = ({ to }: ExternalRedirectionProps) => {
    useEffect(() => {
        window.location.href = to;
    }, [to]);

    return <div>Redirection...</div>;
};

export default ExternalRedirection;
import React, { useState } from "react";
import { Button, Label, TextInput } from "flowbite-react";
import { createCompany } from "../../api/companiesConnector";
import { useAppContext } from "../../../../components/AppContext/AppContext";

export default function CreateCompany() {
    const { throwError, showSuccess } = useAppContext();
    const [companyData, setCompanyData] = useState({
        companyId: "",
        companyName: "",
        companySiret: "",
    });
    const [isLoading, setLoading] = useState(false);
    const [existingCompanies, setExistingCompanies] = useState<string[]>([]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setCompanyData(prevData => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        setExistingCompanies(existingCompanies)

        if (!companyData.companyName) {
            throwError("Tous les champs doivent être remplis!");
            setLoading(false);
            return;
        }

        if (existingCompanies.includes(companyData.companyName)) {
            throwError("Cette entreprise existe déjà!");
            setLoading(false);
            return;
        }

        if (!companyData.companySiret) {
            throwError("Tous les champs doivent être remplis!");
            setLoading(false);
            return
        }

        if (existingCompanies.includes(companyData.companySiret)) {
            throwError("Cette entreprise existe déjà!")
        }

        const companyToSend = {
            company: { id: companyData.companyId },
            name: companyData.companyName,
            siret: companyData.companySiret,
        };

        try {
            await createCompany(companyToSend);
            showSuccess("Fiche créée avec succès !");
        } catch (error) {
            throwError("Erreur lors de la création de la fiche.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="mx-4 p-4 z-0">
            <h2 className="text-xl font-semibold mb-4">Créer une entreprise</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-4 z-0">
                    <Label htmlFor="companyName" value="Dénomination de l'entreprise" />
                    <TextInput
                        id="companyName"
                        name="companyName"
                        required
                        value={companyData.companyName}
                        onChange={handleChange}
                        placeholder="Entrez la dénomination de l'entreprise"
                    />
                    <Label htmlFor="companySiret" value="Siret de l'entreprise" />
                    <TextInput
                        id="companySiret"
                        name="companySiret"
                        required
                        value={companyData.companySiret}
                        onChange={handleChange}
                        placeholder="Entrez le numéro de siret de l'entreprise"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        maxLength={14}
                        minLength={14}
                    />
                </div>

                <Button type="submit" disabled={isLoading}>
                    {isLoading ? "Création en cours..." : "Créer l'entreprise"}
                </Button>
            </form>
        </div>
    );
}

import axios, { AxiosResponse } from "axios";
import Student from "../domain/Student";

const API_URL = window._env_.APPRENTICESHIP_API_URL + "students";

const config = {
    withCredentials: true,
    headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
    },
};

export function getStudents(searchCriteria?: string): Promise<AxiosResponse<Student[]>> {
    const url = `${window._env_.APPRENTICESHIP_API_URL}students`;
    const config = {
        params: { search: searchCriteria || "" },
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.get(url, config);
}
export function getAllStudents(searchCriteria?: string): Promise<AxiosResponse<Student[]>> {
    const url = `${API_URL}`;
    return axios.get(url, config);
}

export function getStudentByName(name: string, searchCriteria?: string): Promise<AxiosResponse<Student>> {
    const url = `${API_URL}/${name}`;
    return axios.get(url, config);
}
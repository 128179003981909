import CreateContract from "./CreateContract";
import {useState} from "react";
import {FindContract} from "./FindContract";
import ContractsLength from "./ContractsLength";

export default function AdminContract () {

    const [isCreateContractOpen, setCreateContractOpen] = useState(false);
    const [isFindContractOpen, setFindContractOpen ] = useState(false);
    const toggleCreateContract= () => {
        setCreateContractOpen(prevState => !prevState);
    };

    const toggleFindContract = () => {
        setFindContractOpen(prevState => !prevState)
    }

    return(
        <div className="my-11 flex flex-col items-center">
        < ContractsLength />
            <button
                onClick={toggleFindContract}
                className={isFindContractOpen ? "w-[95%] p-4 mx-4 apprenticeship-btn-open bg-[#310046] text-white font-bold z-10" : "w-[95%] p-4 mx-4 mb-11 apprenticeship-btn-close  bg-[#310046] text-white font-bold"}>
                {isFindContractOpen ? "Annuler" : "Chercher un contrat"}
            </button>
            <div className={isFindContractOpen ? "w-[95%] p-4 mx-4 apprenticeship-card z-0" : ""}>
                {isFindContractOpen && < FindContract/>}
            </div>
            <button
                onClick={toggleCreateContract}
                className={isCreateContractOpen ? "w-[95%] p-4 mx-4 apprenticeship-btn-open bg-[#310046] text-white font-bold z-10" : "w-[95%] p-4 mx-4 apprenticeship-btn-close  bg-[#310046] text-white font-bold"}>
                {isCreateContractOpen ? "Annuler" : "Créer un contrat"}
            </button>
            <div className={isCreateContractOpen ? "w-[95%] p-4 mx-4 apprenticeship-card z-0" : "mb-11"}>
                {isCreateContractOpen && < CreateContract/>}
            </div>
        </div>
    )
}

import React, { useCallback, useEffect, useState } from "react";
import Company from "../../domain/Company";
import { Card } from "flowbite-react";
import { useAppContext } from "../../../../components/AppContext/AppContext";
import SearchBar from "../../../../components/SearchBar";
import LoadingComponent from "../../../../components/LoadingComponent";
import {getCompanies} from "../../api/companiesConnector";

interface CompanyTableProps {
    companies: Company[];
    onSelectCompany: (companyId: string) => void;
}

const CompanyTable: React.FC<CompanyTableProps> = ({ companies, onSelectCompany }) => {
    return (
        <table className="min-w-full divide-y divide-gray-200">
            <thead>
            <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Dénomination</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Siret</th>
            </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
            {
                companies.map(company => (
                    <tr
                        key={company.id}
                        onClick={() => onSelectCompany(company.id)}
                    className="cursor-pointer hover:bg-gray-100">
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{company.name}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{company.siret}</td>
                    </tr>
                    ))}
            </tbody>
        </table>
    );
};

export default function CompanySearchBar({ onCompanySelect }: { onCompanySelect: (companyId: string) => void }) {
    const [searchCriteria, setSearchCriteria] = useState("");
    const [filteredCompanies, setFilteredCompanies] = useState<Company[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const { throwError } = useAppContext();
    const [noResults, setNoResults] = useState(false);

    const fetchCompanies = useCallback(() => {
        setIsLoading(true);
        getCompanies()
            .then((response) => {
                const allCompanies = response.data;
                const filtered = allCompanies.filter(company =>
                    company.name.toLowerCase().includes(searchCriteria.toLowerCase())
                );
                setFilteredCompanies(filtered);
                setNoResults(filtered.length === 0);
                setIsLoading(false);
            })
            .catch((error) => {
                if (error.response) {
                    switch (error.response.status) {
                        case 401:
                            throwError("Erreur 401 : Non autorisé. Veuillez rafraîchir la page ou vous reconnectez à MyHexagone.");
                            break;
                        case 404:
                            throwError("Erreur 404 : Ressource non trouvée.");
                            break;
                        case 500:
                            throwError("Erreur 500 : Problème serveur. Réessayez plus tard.");
                            break;
                        default:
                            throwError(`Erreur ${error.response.status} : Une erreur inattendue est survenue.`);
                    }
                }
                setIsLoading(false);
            });
    }, [searchCriteria, throwError ]);

    useEffect(() => {
        if (searchCriteria) {
            fetchCompanies();
        } else {
            setFilteredCompanies([]);
            setNoResults(false);
        }
    }, [searchCriteria, fetchCompanies]);

    return (
        <Card className="m-4">
            <div className="inline-flex">
                <SearchBar submit={setSearchCriteria} placeholder="Rechercher une entreprise..." />
            </div>
            <div className="my-2 relative overflow-auto">
                <LoadingComponent isLoading={isLoading}>
                    <CompanyTable companies={filteredCompanies} onSelectCompany={onCompanySelect} />
                    {noResults && (
                        <div className="text-center text-sm text-gray-500 mt-2">
                            Aucune entreprise ne correspond à votre recherche
                        </div>
                    )}
                </LoadingComponent>
            </div>
        </Card>
    );
}
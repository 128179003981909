import axios, { AxiosResponse } from "axios";
import Contract from "../domain/Contract";

const API_URL = window._env_.APPRENTICESHIP_API_URL + "contracts";

const config = {
    withCredentials: true,
    headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
    },
};


export function getContracts(searchCriteria?: string): Promise<AxiosResponse<Contract[]>> {
    const url = `${window._env_.APPRENTICESHIP_API_URL}contracts`;
    const config = {
        params: { search: searchCriteria || "" },
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.get(url, config);
}
export function getAllContracts(): Promise<AxiosResponse<Contract[]>> {
    const url = `${API_URL}`;
    return axios.get(url, config);
}

export function getContractById(contractId: string): Promise<AxiosResponse<Contract>> {
    const url = `${API_URL}/${contractId}`;
    return axios.get(url, config);
}

export function createContract(body: any): Promise<AxiosResponse<Contract>> {
    const url = `${API_URL}`;
    return axios.post(url, body, config);
}

export async function updateContract(contractId: string, body: any): Promise<AxiosResponse<Contract>> {
    const url = `${API_URL}/${contractId}`;
    try {
        console.log('Request body:', JSON.stringify(body, null, 2)); // Debug log
        const response = await axios.patch(url, body, config);
        return response;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            console.error('Error response:', {
                data: error.response.data,
                status: error.response.status,
                headers: error.response.headers
            });
        }
        throw error;
    }
}

export function deleteContract(contractId: string): Promise<AxiosResponse<void>> {
    const url = `${API_URL}/${contractId}`;
    return axios.delete(url, config);
}

import React, { useState } from "react";
import { Button, Label, TextInput } from "flowbite-react";
import { updateContract } from "../../api/contractsConnector";
import { useAppContext } from "../../../../components/AppContext/AppContext";
import CompanySearchBar from "../companies/CompanySearchBar";
import Contract from "../../domain/Contract";

interface UpdateContractProps {
    selectedContract: Contract;
    onUpdateSuccess: () => void;
}

export default function UpdateContract({ selectedContract, onUpdateSuccess }: UpdateContractProps) {
    const { throwError, showSuccess } = useAppContext();
    const [isLoading, setLoading] = useState(false);

    const [contractData, setContractData] = useState({
        startDate: selectedContract.startDate ? new Date(selectedContract.startDate).toISOString().split("T")[0] : "",
        endDate: selectedContract.endDate ? new Date(selectedContract.endDate).toISOString().split("T")[0] : "",
        studentId: selectedContract.student?.id || "",
        studentLastName: selectedContract.student?.lastName || "",
        companyId: selectedContract.company?.id || "",
        companyName: selectedContract.company?.name || "",
        studentFunction: selectedContract.studentFunction || "",
        studentMentor: selectedContract.studentMentor || ""
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setContractData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleCompanySelect = (companyId: string) => {
        setContractData((prevData) => ({ ...prevData, companyId }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);

        // Validation des champs
        if (!contractData.startDate || !contractData.endDate || !contractData.companyId || !contractData.studentId) {
            throwError("Tous les champs doivent être remplis.");
            setLoading(false);
            return;
        }

        if (new Date(contractData.endDate) <= new Date(contractData.startDate)) {
            throwError("La date de fin doit être postérieure à la date de début.");
            setLoading(false);
            return;
        }

        const updatedContract = {
            ...selectedContract,
            startDate: new Date(contractData.startDate).toISOString(),
            endDate: new Date(contractData.endDate).toISOString(),
            student: { id: contractData.studentId },
            studentFunction: contractData.studentFunction,
            studentMentor: contractData.studentMentor,
            company: { id: contractData.companyId },
        };

        try {
            await updateContract(selectedContract.id, updatedContract);
            showSuccess("Contrat mis à jour avec succès !");
            onUpdateSuccess();
        } catch (error) {
            if (error) {
                switch (error) {
                    case 401:
                        throwError("Erreur 401 : Non autorisé. Veuillez rafraîchir la page ou vous reconnectez à MyHexagone.");
                        break;
                    case 404:
                        throwError("Erreur 404 : Ressource non trouvée.");
                        break;
                    case 500:
                        throwError("Erreur 500 : Problème serveur. Réessayez plus tard.");
                        break;
                    default:
                        throwError(`Erreur ${error} : Une erreur inattendue est survenue.`);
                }
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="mx-4 my-11 p-4 bg-white rounded">
            <h2 className="text-xl font-semibold mb-4">Mettre à jour le contrat</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <Label htmlFor="student" value="Étudiant: "/>
                    <TextInput
                        type="text"
                        id="student"
                        name="student"
                        value={contractData.studentLastName}
                        readOnly
                    />
                </div>
                    <div className="mb-4">
                        <Label htmlFor="studentFunction" value="Poste occupé par l'étudiant"/>
                        <TextInput
                            id="studentFunction"
                            name="studentFunction"
                            value={contractData.studentFunction}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-4">
                        <Label htmlFor="studentMentor" value="Nom du tuteur"/>
                        <TextInput
                            id="studentMentor"
                            name="studentMentor"
                            value={contractData.studentMentor}
                            onChange={handleChange}
                        />
                    </div>
                <div className="mb-4">
                    <Label htmlFor="startDate" value="Date de début"/>
                    <TextInput
                        type="date"
                        id="startDate"
                        name="startDate"
                        required
                        value={contractData.startDate}
                        onChange={handleChange}
                    />
                </div>

                <div className="mb-4">
                    <Label htmlFor="endDate" value="Date de fin"/>
                    <TextInput
                        type="date"
                        id="endDate"
                        name="endDate"
                        required
                        value={contractData.endDate}
                        onChange={handleChange}
                    />
                </div>

                <div className="bg-white p-4 mb-3 rounded-xl">
                    <div className="mb-4">
                        <Label htmlFor="studentId" value="ID de l'étudiant"/>
                        <TextInput
                            id="studentId"
                            name="studentId"
                            readOnly
                            value={contractData.studentId}
                            placeholder="Sélectionnez un étudiant dans la barre de recherche"
                        />
                    </div>
                </div>

                <div className="bg-white p-4 mb-3 rounded-xl">
                    <CompanySearchBar onCompanySelect={handleCompanySelect}/>

                    <div className="mb-4">
                        <Label htmlFor="companyId" value="ID de l'entreprise"/>
                        <TextInput
                            id="companyId"
                            name="companyId"
                            required
                            value={contractData.companyId}
                            placeholder="Sélectionnez une entreprise dans la barre de recherche"
                            readOnly
                        />
                    </div>
                </div>

                <Button type="submit" disabled={isLoading}>
                    {isLoading ? "Mise à jour en cours..." : "Mettre à jour le contrat"}
                </Button>
            </form>
        </div>
    );
}

import React, { useState, useEffect } from "react";
import Student from "../../domain/Student";
import { getAllStudents } from "../../api/studentsConnector";

export default function StudentsLength() {
    const [studentsList, setStudentsList] = useState<Student[]>([]);

    useEffect(() => {
        const fetchStudents = async () => {
            try {
                const response = await getAllStudents();
                setStudentsList(response.data);
            } catch (error) {
                console.error("Erreur lors de la récupération des étudiants", error);
            }
        }
        fetchStudents()
    }, []);


    return (
        <div className="mx-4 mb-4 p-4 bg-white rounded shadow-md border-gray-200 dark:border-gray-700">
            <div className="border border-1 font-bold">
                <p>
                    Il y'a actuellement: {studentsList.length} étudiants.
                </p>
                <p className="italic text-xs font-thin">*Lorsque le compteur affiche zéro, actualisez la page</p>
            </div>
        </div>
    );
}

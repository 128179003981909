import {useState} from "react";
import AdminCompany from "./AdminCompany";
import "../../../../index.css";

export default function Company() {

    const [isAdminCompanyOpen, setAdminCompanyOpen] = useState(false);

    const toggleAdminCompany= () => {
        setAdminCompanyOpen(prevState => !prevState);
    };

    return(
        <>
            <button
                onClick={toggleAdminCompany}
                className ={ isAdminCompanyOpen ?"p-4 mx-4 apprenticeship-btn-open bg-[#310046] z-10 text-white font-bold" : "p-4 mx-4 apprenticeship-btn-close bg-[#310046] text-white font-bold"}>
                { isAdminCompanyOpen ? "Annuler" : "Gestion des entreprises"}
            </button>
            <div className={ isAdminCompanyOpen ?"p-4 mx-4 apprenticeship-card slideInDown z-0" : ""}>
                { isAdminCompanyOpen && < AdminCompany />}
            </div>
        </>
    )
}
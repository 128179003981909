import React, { useCallback, useEffect, useState } from "react";
import Student from "../../domain/Student";
import { Card } from "flowbite-react";
import { useAppContext } from "../../../../components/AppContext/AppContext";
import SearchBar from "../../../../components/SearchBar";
import LoadingComponent from "../../../../components/LoadingComponent";
import { getStudents } from "../../api/studentsConnector";
import StudentsLength from "./StudentsLength";

interface StudentTableProps {
    students: Student[];
    onSelectStudent: (studentId: string, lastName: string) => void;
}

const StudentTable: React.FC<StudentTableProps> = ({ students, onSelectStudent }) => {
    return (
        <>
        <table className="min-w-full divide-y divide-gray-200">
            <thead>
            <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nom</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Prénom</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th>
            </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
            {students.map(student => (
                <tr
                    key={student.id}
                    onClick={() => onSelectStudent(student.id, student.lastName)} // Modification ici
                    className="cursor-pointer hover:bg-gray-100"
                >
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{student.lastName}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{student.firstName}</td>
                </tr>
            ))}
            </tbody>
        </table>
        </>
    );
};

export default function StudentSearchBar({ onStudentSelect }: { onStudentSelect: (studentId: string, lastName: string) => void }) {
    const [searchCriteria, setSearchCriteria] = useState("");
    const [filteredStudents, setFilteredStudents] = useState<Student[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const { throwError } = useAppContext();
    const [noResults, setNoResults] = useState(false);

    const fetchStudents = useCallback(() => {
        setIsLoading(true);
        getStudents()
            .then((response) => {
                const allStudents = response.data;
                const filtered = allStudents.filter(student =>
                    student.lastName.toLowerCase().includes(searchCriteria.toLowerCase()) ||
                    student.firstName.toLowerCase().includes(searchCriteria.toLowerCase())
                );
                setFilteredStudents(filtered);
                setNoResults(filtered.length === 0);
                setIsLoading(false);
            })
            .catch((error) => {
                    if (error.response) {
                        switch (error.response.status) {
                            case 401:
                                throwError("Erreur 401 : Non autorisé. Veuillez rafraîchir la page ou vous reconnectez à MyHexagone.");
                                break;
                            case 404:
                                throwError("Erreur 404 : Ressource non trouvée.");
                                break;
                            case 500:
                                throwError("Erreur 500 : Problème serveur. Réessayez plus tard.");
                                break;
                            default:
                                throwError(`Erreur ${error.response.status} : Une erreur inattendue est survenue.`);
                        }
                    }
                setIsLoading(false);
            });
    }, [searchCriteria, throwError]);

    useEffect(() => {
        if (searchCriteria) {
            fetchStudents();
        } else {
            setFilteredStudents([]);
            setNoResults(false);
        }
    }, [searchCriteria, fetchStudents]);

    return (
        <Card className="m-4">
            <StudentsLength />
            <div className="inline-flex">
                <SearchBar submit={setSearchCriteria} placeholder="Rechercher un étudiant..." />
            </div>
            <div className="my-2 relative overflow-auto">
                <LoadingComponent isLoading={isLoading}>
                    <StudentTable students={filteredStudents} onSelectStudent={onStudentSelect} />
                    {noResults && (
                        <div className="text-center text-sm text-gray-500 mt-2">
                            Aucun étudiant ne correspond à votre recherche
                        </div>
                    )}
                </LoadingComponent>
            </div>
        </Card>
    );
}

import React, { useState } from "react";
import { Button, Table } from "flowbite-react";
import { deleteContract } from "../../api/contractsConnector";
import LoadingComponent from "../../../../components/LoadingComponent";
import { useAppContext } from "../../../../components/AppContext/AppContext";

interface DeleteContractProps {
    selectedContract: string | null;
    onDeleteSuccess: () => void;
}

export default function DeleteContract({ selectedContract, onDeleteSuccess }: DeleteContractProps) {
    const { throwError, showSuccess } = useAppContext();
    const [isLoading, setIsLoading] = useState(false);

    const handleDelete = () => {
        if (!selectedContract) return;

        setIsLoading(true);
        deleteContract(selectedContract)
            .then(() => {
                showSuccess("Contrat supprimé avec succès !");
                onDeleteSuccess();
            })
            .catch((error) => {
                if (error.response) {
                    switch (error.response.status) {
                        case 401:
                            throwError("Erreur 401 : Non autorisé. Veuillez rafraîchir la page ou vous reconnectez à MyHexagone.");
                            break;
                        case 404:
                            throwError("Erreur 404 : Ressource non trouvée.");
                            break;
                        case 500:
                            throwError("Erreur 500 : Problème serveur. Réessayez plus tard.");
                            break;
                        default:
                            throwError(`Erreur ${error.response.status} : Une erreur inattendue est survenue.`);
                    }
                }
                setIsLoading(false);
            });
    };

    return (
        <div className="mx-4 my-11 w-full flex items-center">
            <LoadingComponent isLoading={isLoading}>
                {selectedContract && (
                    <Table>
                        <thead>
                        <tr>
                            <th>ID du contrat</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{selectedContract}</td>
                            <td>
                                <Button color="failure" onClick={handleDelete}>
                                    Supprimer
                                </Button>
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                )}

                {!selectedContract && (
                    <div className="text-center text-sm text-gray-500 mt-2">
                        Aucun contrat sélectionné.
                    </div>
                )}
            </LoadingComponent>
        </div>
    );
}

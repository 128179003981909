import Contract from "../components/contracts/Contract";
import "../../../global.css";
import '../../../index.css';
import Company from "../components/companies/Company";
export default function Apprenticeship() {
    return (
        <>
            <h1 className="text-center text-4xl uppercase text-[#310046] my-20 font-bold">Gestion des contrats
                d'alternances</h1>
            <div className="flex flex-col my-11">
                <div className="w-full flex flex-col mb-11 px-5 overflow-hidden">
                    <Contract/>
                </div>
                <div className="w-full flex flex-col px-5 overflow-clip">
                    <Company/>
                </div>
            </div>
        </>
    );
}
import React, { useState } from "react";
import { Button, Label, TextInput } from "flowbite-react";
import { useAppContext } from "../../../../components/AppContext/AppContext";
import StudentSearchBar from "../students/StudentSearchBar";
import CompanySearchBar from "../companies/CompanySearchBar";
import {createContract} from "../../api/contractsConnector";

export default function CreateContract() {
    const { throwError, showSuccess } = useAppContext();
    const [contractData, setContractData] = useState({
        startDate: "",
        endDate: "",
        studentId: "",
        studentLastName: "",
        companyId: "",
        studentFunction: "",
        studentMentor: ""
    });
    const [isLoading, setLoading] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setContractData(prevData => ({ ...prevData, [name]: value }));
    };

    const handleStudentSelect = (studentId: string, studentLastName: string) => {
        setContractData(prevData => ({ ...prevData, studentId, studentLastName }));
    };

    const handleCompanySelect = (companyId: string) => {
        setContractData(prevData => ({ ...prevData, companyId }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);

        if (!contractData.startDate || !contractData.endDate || !contractData.companyId || !contractData.studentId || !contractData.studentFunction) {
            throwError("Tous les champs doivent être remplis.");
            setLoading(false);
            return;
        }

        if (new Date(contractData.endDate) <= new Date(contractData.startDate)) {
            throwError("La date de fin doit être postérieure à la date de début.");
            setLoading(false);
            return;
        }

        const startDateISO = new Date(contractData.startDate).toISOString();
        const endDateISO = new Date(contractData.endDate).toISOString();

        const contractToSend = {
            startDate: startDateISO,
            endDate: endDateISO,
            company: { id: contractData.companyId },
            student: { id: contractData.studentId },
            studentFunction: contractData.studentFunction,
            studentMentor: contractData.studentMentor
        };
        try {
            await createContract(contractToSend);
            showSuccess("Contrat créé avec succès !");
        } catch (error) {
            switch (error) {
                case 401:
                    throwError("Erreur 401 : Non autorisé. Veuillez rafraîchir la page ou vous reconnectez à MyHexagone.");
                    break;
                case 404:
                    throwError("Erreur 404 : Ressource non trouvée.");
                    break;
                case 500:
                    throwError("Erreur 500 : Problème serveur. Réessayez plus tard.");
                    break;
                default:
                    throwError(`Erreur ${error} : Une erreur inattendue est survenue.`);
            }
                }
            setLoading(false);
        }

    return (
        <div className="mx-4 p-4">
            <h2 className="text-xl font-semibold mb-4">Créer un contrat</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <Label htmlFor="startDate" value="Date de début"/>
                    <TextInput
                        type="date"
                        id="startDate"
                        name="startDate"
                        required
                        value={contractData.startDate}
                        onChange={handleChange}
                    />
                </div>

                <div className="mb-4">
                    <Label htmlFor="endDate" value="Date de fin"/>
                    <TextInput
                        type="date"
                        id="endDate"
                        name="endDate"
                        required
                        value={contractData.endDate}
                        onChange={handleChange}
                    />
                </div>

                <div className="bg-white p-4 mb-3 rounded-xl">
                    <StudentSearchBar onStudentSelect={handleStudentSelect}/>

                    <div className="mb-4">
                        <Label htmlFor="studentId" value="ID de l'étudiant"/>
                        <TextInput
                            id="studentId"
                            name="studentId"
                            disabled
                            value={contractData.studentId}
                            placeholder="Sélectionnez un étudiant dans la barre de recherche"
                        />
                    </div>
                    <div className="mb-4">
                        <Label htmlFor="studentLastName" value="Nom de l'étudiant"/>
                        <TextInput
                            id="studentLastName"
                            name="studentLastName"
                            disabled
                            value={contractData.studentLastName}
                        />
                    </div>
                    <div className="mb-4">
                        <Label htmlFor="studentFunction" value="Poste occupé par l'étudiant"/>
                        <TextInput
                            id="studentFunction"
                            name="studentFunction"
                            value={contractData.studentFunction}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-4">
                        <Label htmlFor="studentMentor" value="Nom du tuteur"/>
                        <TextInput
                            id="studentMentor"
                            name="studentMentor"
                            value={contractData.studentMentor}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="bg-white p-4 mb-3 rounded-xl">
                    <CompanySearchBar onCompanySelect={handleCompanySelect}/>

                    <div className="mb-4">
                        <Label htmlFor="companyId" value="ID de l'entreprise"/>
                        <TextInput
                            id="companyId"
                            name="companyId"
                            required
                                value={contractData.companyId}
                                onChange={handleChange}
                                placeholder="Sélectionnez une entreprise dans la barre de recherche"
                            />
                        </div>
                    </div>

                    <Button type="submit" disabled={isLoading}>
                        {isLoading ? "Création en cours..." : "Créer le contrat"}
                    </Button>
            </form>
        </div>
);
}

import React, {useState} from "react";
import CompanySearchBar from "./CompanySearchBar";
import DeleteCompany from "./DeleteCompany";
import Company from "../../domain/Company";
import {getCompanyById} from "../../api/companiesConnector";
import UpdateCompany from "./UpdateCompany";

export const FindCompany = () => {
    const [selectedCompanyId, setSelectedCompanyId] = useState<string | null>(null);
    const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);
    const handleCompanySelect = async (companyId: string) => {
        setSelectedCompanyId(companyId);

        try {
            const response = await getCompanyById(companyId);
            setSelectedCompany(response.data);
        } catch (error) {
            console.error("Erreur lors de la récupération des entreprises", error);
        }
    };

    const handleUpdateSuccess = () => {
        setSelectedCompanyId(null);
        setSelectedCompany(null);
    };

    return (
        <div className="mx-4 p-4 bg-white rounded shadow-md border-gray-200 dark:border-gray-700">
            <h2 className="text-xl font-semibold mb-4">Rechercher, mettre à jour ou supprimer une fiche entreprise</h2>

            <CompanySearchBar onCompanySelect={handleCompanySelect} />

            {selectedCompany && (
                <div className="mt-4">
                    <UpdateCompany
                        selectedCompany={selectedCompany}
                        onUpdateSuccess={handleUpdateSuccess}
                    />
                </div>)}
            {selectedCompany && (
                <div className="mt-4 ">
                    <DeleteCompany
                    selectedCompanyId={selectedCompanyId}
                    onDeleteSuccess={handleUpdateSuccess}
                    selectedCompany={selectedCompany}
                    />
                </div>)}
        </div>
    );
};

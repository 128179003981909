import {useState} from "react";
import "../../../../index.css";
import AdminContract from "./AdminContract";

export default function Contract() {

    const [isAdminContractOpen, setAdminContractOpen] = useState(false);

    const toggleAdminContract= () => {
        setAdminContractOpen(prevState => !prevState);
    };

    return(
        <>
          <button
              onClick={toggleAdminContract}
              className ={ isAdminContractOpen ?"p-4 mx-4 apprenticeship-btn-open bg-[#310046] text-white font-bold z-10" : "p-4 mx-4 apprenticeship-btn-close  bg-[#310046] text-white font-bold"}>
              { isAdminContractOpen ? "Annuler" : "Gestion des contrats"}
          </button>
            <div className={ isAdminContractOpen ?"p-4 mx-4 apprenticeship-card slideInDown z-0" : ""}>
              { isAdminContractOpen && < AdminContract />}
            </div>
        </>
    )
}
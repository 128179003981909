import React, { useState, useEffect } from "react";
import Contract from "../../domain/Contract";
import { getAllContracts } from "../../api/contractsConnector";

export default function ContractsLength() {
    const [contractsList, setContractsList] = useState<Contract[]>([]);

    useEffect(() => {
        const fetchContracts = async () => {
            try {
                const response = await getAllContracts();
                setContractsList(response.data);
            } catch (error) {
                console.error("Erreur lors de la récupération des contrats", error);
            }
        };
        fetchContracts();
    }, []);


    return (
        <div className="mx-4 mb-4 p-4 bg-white rounded shadow-md border-gray-200 dark:border-gray-700">
            <div className="border border-1 font-bold">
                <p>
                    Il y'a actuellement: {contractsList.length} contrats actifs
                </p>
                <p className="italic text-xs font-thin">*Lorsque le compteur affiche zéro, actualisez la page</p>
            </div>
        </div>
    );
}

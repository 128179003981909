import React, { useState } from "react";
import ContractSearchBar from "./ContractSearchBar";
import DeleteContract from "./DeleteContract";
import UpdateContract from "./UpdateContract";
import Contract from "../../domain/Contract";
import { getContractById } from "../../api/contractsConnector";

export const FindContract = () => {
    const [selectedContractId, setSelectedContractId] = useState<string | null>(null);
    const [selectedContract, setSelectedContract] = useState<Contract | null>(null);

    const handleContractSelect = async (contractId: string) => {
        setSelectedContractId(contractId);

        try {
            const response = await getContractById(contractId);
            setSelectedContract(response.data);
        } catch (error) {
            console.error("Erreur lors de la récupération du contrat", error);
        }
    };

    const handleDeleteSuccess = () => {
        setSelectedContractId(null);
        setSelectedContract(null);
    };

    const handleUpdateSuccess = () => {
        console.log("Contrat mis à jour avec succès !");
    };

    return (
        <div className="mx-4 p-4">
            <h2 className="text-xl font-semibold mb-4">Rechercher, mettre à jour ou supprimer un contrat</h2>

            <ContractSearchBar onContractSelect={handleContractSelect} />

            {selectedContract && (
                <div className="mt-4 p-4">
                    <DeleteContract
                        selectedContract={selectedContractId}
                        onDeleteSuccess={handleDeleteSuccess}
                    />
                    <UpdateContract
                        selectedContract={selectedContract}
                        onUpdateSuccess={handleUpdateSuccess}
                    />
                </div>
            )}
        </div>
    );
};

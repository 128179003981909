import React, { useState } from "react";
import {Button, Table} from "flowbite-react";
import { deleteCompany } from "../../api/companiesConnector";
import LoadingComponent from "../../../../components/LoadingComponent";
import { useAppContext } from "../../../../components/AppContext/AppContext";
import Company from "../../domain/Company";

interface DeleteCompanyProps {
    selectedCompanyId: string | null;
    onDeleteSuccess: () => void;
    selectedCompany: Company;
}

const DeleteCompany: React.FC<DeleteCompanyProps> = ({ selectedCompanyId, onDeleteSuccess, selectedCompany }) => {
    const { throwError, showSuccess } = useAppContext();
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState<Company>(selectedCompany);

    const handleDelete = async () => {
        setIsLoading(true);
        setFormData(formData)
        try {
            await deleteCompany(selectedCompanyId);
            showSuccess("Entreprise supprimée avec succès !");
            onDeleteSuccess();
        } catch (error) {
            switch (error) {
                case 401:
                    throwError("Erreur 401 : Non autorisé. Veuillez rafraîchir la page ou vous reconnectez à MyHexagone.");
                    break;
                case 404:
                    throwError("Erreur 404 : Ressource non trouvée.");
                    break;
                case 500:
                    throwError("Erreur 500 : Problème serveur. Réessayez plus tard.");
                    break;
                default:
                    throwError(`Erreur ${error} : Une erreur inattendue est survenue.`);
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="mx-4 px-4">
            <LoadingComponent isLoading={isLoading}>
                <Table className="mt-4">
                    <thead >
                    <tr>
                        <th>Dénomination</th>
                        <th className="px-2">ID</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody className="p-4">
                    <tr>
                        <td>
                            <input
                                type="text"
                                name="name"
                                value={formData.name}
                                readOnly
                            />
                        </td>
                        <td className="px-2">{formData.id}</td>
                        <td>
                            <Button className="w-32" color="success" onClick={handleDelete}>
                                Supprimer
                            </Button>
                        </td>
                    </tr>
                    </tbody>
                </Table>
            </LoadingComponent>
        </div>
    );
};

export default DeleteCompany;

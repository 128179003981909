import {useEffect, useState} from "react";
import {createContainer} from "unstated-next";
import {ApplicationCategory} from "../../domain/Application";
import {Alert} from "../Toast/ToastLauncher";
import {getUserContext} from "../../api/userConnector/userContextConnector";

interface AppContextValue {
    fullName: string,
    access: ApplicationCategory[];
    roles: string[];
    error: boolean;
    isAppContextLoading: boolean;
    toastMessage: string;
    toastVariant: Alert;
    toastStatus: boolean;
    setToastStatus: (status: boolean) => void;
    throwError: (message: string) => void;
    showNotification: (variant: Alert, message: string) => void;
    showSuccess: (message: string) => void;
}

export const useAppContextProvider = (): AppContextValue => {
    const [fullName, setFullName] = useState<string>("");
    const [access, setAccess] = useState<ApplicationCategory[]>([]);
    const [roles, setRoles] = useState<string[]>([]);
    const [error, setError] = useState(false);
    const [isAppContextLoading, setAppContextLoading] = useState(true);
    const [toastStatus, setToastStatus] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [toastVariant, setToastVariant] = useState<Alert>("info");

    function showNotification(variant: Alert, message: string) {
        showToaster(variant, message);
    }

    function throwError(message: string) {
        showToaster("error", message);
    }

    function showToaster(variant: Alert, message: string) {
        setToastVariant(variant);
        setToastMessage(message);
        setToastStatus(true);
        setTimeout(() => setToastStatus(false), 10000);
    }

    function showSuccess(message: string) {
        showToaster("error", message);
    }

    useEffect(() => {
        setAppContextLoading(true);
        getUserContext()
            .then(userContextAxiosResponse => {
                setFullName(userContextAxiosResponse.data.fullName);
                setAccess(userContextAxiosResponse.data.access);
                setAppContextLoading(false);
                setRoles(userContextAxiosResponse.data.roles)
            }).catch(() => {
            setError(true);
            setAppContextLoading(false);
        });
    }, []);

    return {fullName, access, roles, error, isAppContextLoading, toastMessage, toastVariant, toastStatus, setToastStatus, throwError, showNotification, showSuccess};
};

export const AppContextContainer = createContainer(useAppContextProvider);

export const useAppContext = () => {
    return AppContextContainer.useContainer();
};
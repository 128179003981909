import React, {useEffect, useState} from "react";
import Company from "../../domain/Company";
import { getAllCompanies } from "../../api/companiesConnector";

export default function CompaniesLength() {
    const [companiesList, setCompaniesList] = useState<Company[]>([]);

    useEffect(() => {
        const fetchCompanies = async () => {
            try {
                const response = await getAllCompanies();
                setCompaniesList(response.data);
            } catch (error) {
                console.error("Erreur lors de la récupération des entreprises, error");
            }
        };
        fetchCompanies();
    }, []);


    return (
        <div className="mx-4 mb-4 p-4 bg-white rounded shadow-md border-gray-200 dark:border-gray-700">
            <div className="border border-1 font-bold">
                <p>
                    Il y'a actuellement: {companiesList.length} entreprises
                </p>
                <p className="italic text-xs font-thin">*Lorsque le compteur affiche zéro, actualisez la page</p>
            </div>
        </div>
    );
}
